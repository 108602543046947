import React from 'react';
import RssIcon from './../../assets/images/rss.svg';
import styled from 'styled-components';
import ToggleColorIcon from '../Icons/ToggleColorIcon';
import useDarkMode from '../../hooks/useDarkMode';
import ClientOnly from '../ClientOnly';
import Boop from 'components/Boop';
const HeaderIconWraper = styled.div`
  display: flex;
  flex-direction: row;
  width: 200px;
  justify-content: space-around;

  & svg {
    fill: var(--color-text);
    height: 24px;
  }
`;

const IconButton = styled.button`
  background-color: transparent;
  cursor: pointer;
`;

const HeaderIcons = () => {
  const [colorMode, toggleColorMode] = useDarkMode();

  return (
    <ClientOnly>
      <HeaderIconWraper>
        <Boop scale={1.1} timing={200}>
          <IconButton
            onClick={toggleColorMode}
            alt="Button for Dark Mode Toggle"
            title="Toggle Dark Mode"
          >
            <ToggleColorIcon colorMode={colorMode}></ToggleColorIcon>
          </IconButton>
        </Boop>
        <Boop scale={1.1} timing={200}>
          <IconButton alt="Button for RSS Feed" title="RSS Feed">
            <a href="/rss.xml">
              <RssIcon></RssIcon>
            </a>
          </IconButton>
        </Boop>
      </HeaderIconWraper>
    </ClientOnly>
  );
};

export default HeaderIcons;
