import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import HeaderIcons from './HeaderIcons';

const MobileMenuWrapper = styled.div`
  display: ${(props) => (props.active ? 'flex' : 'none')};

  position: fixed;
  pointer-events: auto;
  width: 100%;
  height: 100vh;
  animation: fadeIn 1 300ms;

  /* display: flex; */
  z-index: 3;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 10em 1em;
  margin: 0;
  transition: all 1s ease-in;
  background-color: var(--color-background);
  opacity: 0.95;

  /* @media (max-width: 768px) {
    flex-direction: column;

    padding-top: 1em;
    justify-content: flex-center;
    & .nav-link {
      display: none;
    }

    & .header-icons {
      display: none;
    }
  } */
`;
const MobileMenuLink = styled(Link)`
  display: block;
  /* background-color: var(--color-gray-200); */
  padding: 24px 48px;
  margin-bottom: 1em;
  text-align: center;
  border-radius: 15px;
  font-size: 1.5rem;
  width: 300px;

  /* The GitHub button is a primary button
   * edit this to target it specifically! */
  ${(props) =>
    props.active &&
    css`
      font-weight: var(--font-weight-bold);
      background-color: rgba(255, 255, 255, 0.1);
    `}
`;

const MobileMenu = ({ show = false, linkClicked, navLinks, currentPath }) => {
  return (
    <MobileMenuWrapper active={show} className="bg-frosted">
      {navLinks.map((navLink) => (
        <MobileMenuLink
          active={currentPath == navLink.href}
          onClick={linkClicked}
          to={navLink.href}
        >
          {navLink.title}
        </MobileMenuLink>
      ))}

      <HeaderIcons></HeaderIcons>
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
