import React from 'react';
import Icon from '../../../assets/images/logo.svg';
const Logo = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
      }}
    >
      <h1 className="logo-brand">George Nance</h1>
    </div>
  );
};

export default Logo;
