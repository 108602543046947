import React, { Component } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { device } from '../../utils/breakpoints';
import styled from 'styled-components';
import { IoLogoTwitter, IoLogoGithub, IoMdMail } from 'react-icons/io';

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  font-family: var(--font-family-ui);
  font-weight: 400;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 20px;
`;

const FooterItem = styled.p`
  /* display: flex; */
  /* flex-direction: column; */
  color: var(--color-text);
  font-size: var(--font-size-sm);

  strong {
  }
`;
const WebRing = styled(FooterItem)`
  /* flex: 1 1; */
  @media ${device.medium} {
    width: auto;
  }
  width: 100%;
  /* margin: auto 0; */
  text-align: center;
  font-size: var(--font-size-md);
`;
const ContactLink = styled.a`
  color: var(--color-text);
  font-size: var(--font-size-xs);

  transition: all 300ms ease-out;
  & :hover {
    color: var(--color-primary);
  }

  /* & :hover ::after {
    content: ' ✉️';
    margin-left: 6px;
  } */
`;
const Footer = () => {
  const { site } = useStaticQuery(query);
  const author = site.siteMetadata.author;

  return (
    <FooterWrapper className="h-card" role="contentinfo">
      <div hidden>
        <img
          loading="lazy"
          class="u-photo"
          src={`${site.siteMetadata.siteUrl}${author.avatar}`}
        />
        <p className="p-note">{site.siteMetadata.description}</p>
        <a href={`mailto:${author.email}`} rel="me" className="u-email">
          {author.email}
        </a>
      </div>

      <FooterItem>
        <strong>&copy; 2018&ndash;{site.buildTime}</strong>
        <br />
        <a className="p-name u-url" rel="me" href={site.siteMetadata.siteUrl}>
          {author.name}
        </a>
      </FooterItem>
      {/* <FooterItem>
        <strong>Subscribe</strong>
        <br />
        <a href="/mailinglist">Mailing List</a>
      </FooterItem> */}

      <FooterItem>
        <strong>Social</strong>
        <br />
        <a href={author.social.twitter.url} title="Twitter" rel="me">
          <IoLogoTwitter />
          &nbsp;Twitter
        </a>
        &nbsp;/&nbsp;
        <a href={author.social.github.url} title="Github" rel="me">
          <IoLogoGithub />
          &nbsp;Github
        </a>
      </FooterItem>
      <WebRing>
        <a href="https://xn--sr8hvo.ws/%F0%9F%9A%B1%F0%9F%9A%84%F0%9F%8C%89/previous">
          ←
        </a>
        🕸💍
        <a href="https://xn--sr8hvo.ws/%F0%9F%9A%B1%F0%9F%9A%84%F0%9F%8C%89/next">
          →
        </a>
      </WebRing>
    </FooterWrapper>
  );
};

export default Footer;

const query = graphql`
  query Author {
    site {
      buildTime(formatString: "YYYY")
      siteMetadata {
        description
        siteUrl
        author {
          avatar
          email
          name
          social {
            github {
              name
              url
            }
            instagram {
              name
              url
            }
            twitter {
              name
              url
            }
          }
        }
      }
    }
  }
`;
