import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const BlogSchema = ({
  title,
  url,
  datePublished,
  dateModified,
  image,
  description,
  wordCount,
}) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${url}`,
    },
    headline: `${title}`,
    image: [
      `${image}`,
      //Todo add different image tags https://developers.google.com/search/docs/data-types/article#non-amp
    ],
    datePublished: `${datePublished}`,
    dateModified: `${dateModified}`,
    author: {
      '@type': 'Person',
      name: 'George Nance',
    },
    publisher: {
      '@type': 'Person',
      name: 'George Nance',
    },
    description: description,
    wordcount: wordCount,
  };
};

const SEO = ({
  title,
  description,
  image,
  type,
  datePublished,
  dateModified,
  wordCount,
}) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    monetization,
    social: { twitter },
  } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  let schema = null;

  if (type === 'article') {
    schema = BlogSchema({
      title: seo.title,
      datePublished: datePublished,
      dateModified: dateModified,
      description: seo.description,
      url: seo.url,
      image: seo.image,
      wordCount: wordCount,
    });
  }

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="author" content="George Nance"></meta>
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(type === 'article' ? true : null) && (
        <meta property="og:type" content="article" />
      )}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {twitter && <meta name="twitter:creator" content={twitter} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      <meta name="monetization" content={monetization}></meta>

      <link
        rel="webmention"
        href="https://webmention.io/georgenance.com/webmention"
      />
      <link
        rel="pingback"
        href="https://webmention.io/georgenance.com/xmlrpc"
      />
      <meta name="msvalidate.01" content="37BB9AF15D4F626B8B134B451F7E3740" />
      {/* inline script elements */}
      {schema && (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}
    </Helmet>
  );
};
export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        defaultImage: image
        social {
          twitter
        }
        monetization
      }
    }
  }
`;
