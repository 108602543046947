import React, { useState } from 'react';
import { Link } from 'gatsby';

import styled, { css } from 'styled-components';
import HeaderIcons from './HeaderIcons';
import { device } from './../../utils/breakpoints';
import MobileMenu from './MobileMenu';
import Logo from './Logo/Logo';
import useLockScroll from '../../hooks/useLockScroll';

const LogoWrapper = styled.span`
  margin-right: 1em;
  /* position: absolute; */
  z-index: 4;
  /* margin: auto 0; */
  top: 14px;

  ${device.medium} {
  }
`;

const HeaderWrapper = styled.header`
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--font-family-ui);
  @media ${device.medium} {
    padding-left: 32px;
    padding-right: 32px;
    /* justify-content: space-around; */
  }

  position: sticky;
  top: 0px;
  z-index: 5;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const NavLink = styled(Link)`
  /* margin: 0 8px; */
  padding: 8px 16px;
  border-radius: 10px;
  /* The GitHub button is a primary button
   * edit this to target it specifically! */
  ${(props) =>
    props.active &&
    css`
      font-weight: var(--font-weight-bold);
      background-color: rgba(255, 255, 255, 0.1);
    `}
`;

const Nav = styled.nav`
  display: none;

  @media ${device.medium} {
    display: flex;
    flex: 1;
    /* justify-content: space-around; */
  }
`;

const Right = styled.div`
  display: none;
  @media ${device.medium} {
    display: block;
  }
`;
const HamburgerMenuIcon = styled.button`
  padding: 0;
  position: absolute;
  right: 40px;
  top: 20px;
  width: 32px;
  height: 24px;
  z-index: 4;
  background: transparent;
  &:after,
  &:before {
    background-color: var(--color-text);
    border-radius: 3px;
    content: '';
    display: block;
    height: 5px;
    margin: 7px 0;
    transition: all 0.2s ease-in-out;
  }
  @media ${device.medium} {
    display: none;
  }

  ${(props) =>
    props.active &&
    css`
      &:before {
        transform: translateY(6px) rotate(135deg) scale(1.1);
        /* color: palevioletred; */
      }
      &:after {
        transform: translateY(-6px) rotate(-135deg) scale(1.1);
      }
    `}
`;

const Header = (props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [scrollMode, setScrollMode] = useLockScroll();

  const navLinks = [
    { title: 'Latest', href: '/latest' },
    { title: 'About', href: '/about' },
    { title: 'Now', href: '/now' },
    // { title: 'Games', href: '/games' },
  ];
  let currentPath;
  if (!props) {
    currentPath = window.location.pathname;
  } else {
    currentPath = '/';
  }

  let handleHamburgerClick = () => {
    setMenuOpen(!isMenuOpen);
    setScrollMode(!scrollMode);
  };

  return (
    <HeaderWrapper>
      <LogoWrapper>
        <Link to="/">
          <Logo></Logo>
        </Link>
      </LogoWrapper>

      <Nav>
        {navLinks.map((navLink) => (
          <NavLink
            active={currentPath == navLink.href}
            key={navLink.href}
            to={navLink.href}
          >
            {navLink.title}
          </NavLink>
        ))}
      </Nav>
      <Right>
        <HeaderIcons></HeaderIcons>
      </Right>

      <HamburgerMenuIcon
        alt="Button for expanding the mobile navigation menu"
        active={isMenuOpen}
        onClick={handleHamburgerClick}
      ></HamburgerMenuIcon>
      <MobileMenu
        show={isMenuOpen}
        currentPath={currentPath}
        navLinks={navLinks}
        linkClicked={() => setMenuOpen(false)}
      ></MobileMenu>
    </HeaderWrapper>
  );
};
export default Header;
