import React from 'react';

import SunIcon from '../../assets/images/sun.svg';
import MoonIcon from '../../assets/images/moon.svg';

const ToggleColorIcon = ({ colorMode }) => {
  if (!colorMode) {
    return null;
  }

  return colorMode === 'light' ? <SunIcon /> : <MoonIcon />;
};

export default ToggleColorIcon;
