import { useEffect, useState } from 'react';

const useLockScroll = () => {
  const [scrollMode, setScrollMode] = useState(false);
  useEffect(() => {
    if (scrollMode) {
      window.document.body.classList.add('no-scroll');
    } else {
      window.document.body.classList.remove('no-scroll');
    }
  }, [scrollMode]);

  return [scrollMode, setScrollMode];
};

export default useLockScroll;
