import { useEffect, useState } from 'react';

const useDarkMode = () => {
  const [colorMode, setColorMode] = useState(() => {
    try {
      const persistedColorPreference = window.localStorage.getItem(
        'color-mode'
      );
      const hasPersistedPreference =
        typeof persistedColorPreference === 'string';
      // If the user has explicitly chosen light or dark,
      // let's use it. Otherwise, this value will be null.
      if (hasPersistedPreference) {
        return persistedColorPreference;
      }
      // If they haven't been explicit, let's check the media
      // query
      const mql = window.matchMedia('(prefers-color-scheme: dark)');
      const hasMediaQueryPreference = typeof mql.matches === 'boolean';
      if (hasMediaQueryPreference) {
        return mql.matches ? 'dark' : 'light';
      }
      // If they are using a browser/OS that doesn't support
      // color themes, let's default to 'light'.
      return 'light';
    } catch (e) {
      return 'light';
    }
  });
  useEffect(() => {
    window.localStorage.setItem('color-mode', colorMode);
    window.document.documentElement.dataset.darkMode = colorMode === 'dark';
  }, [colorMode]);

  const toggleColorMode = () => {
    const newMode = colorMode === 'light' ? 'dark' : 'light';
    setColorMode(newMode);
  };

  return [colorMode, toggleColorMode];
};

export default useDarkMode;
